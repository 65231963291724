import Link from 'next/link';
import { Dispatch, SetStateAction } from 'react';
import { routeNames } from '@/constants/routes';
import { CrossIcon, LogoBlack } from '@/images/svg';

export const MobileMenuHeader = ({ setOpen }: { setOpen: Dispatch<SetStateAction<boolean>> }) => {
  return (
    <div className="border-b-[1px] border-grey-1">
      <div className="flex flex-row justify-between py-base pl-base pr-xs">
        <Link
          scroll={true}
          prefetch={false}
          href={`${routeNames.home}`}
          data-testid="header-logo-mobile"
          className="block text-teal-600 py-[4px]"
          aria-label="Syberry"
        >
          <LogoBlack
            width={113}
            height={32}
          />
        </Link>
        <button
          className="w-[40px] h-[40px] flex items-center justify-center"
          data-testid="cross-icon"
          onClick={() => setOpen(false)}
          title="Close"
        >
          <CrossIcon />
        </button>
      </div>
    </div>
  );
};
